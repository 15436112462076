import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaTrash, FaArrowLeft, FaSave, FaTimes } from "react-icons/fa";
import apiClient from "../utils/axiosConfig";
import { format } from "date-fns";

function WarrantyContent({
  deviceData: initialDeviceData,
  type: initialDeviceType,
}) {
  const [warrantyStatus, setWarrantyStatus] = useState();
  const [deviceType, setDeviceType] = useState("");
  const [isDetailsEdit, setIsDetailsEdit] = useState(false);
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const [type, setType] = useState(initialDeviceType);
  const [warrantyHistory, setWarrantyHistory] = useState([]);
  const [editedData, setEditedData] = useState(deviceData);
  const [isDetailsChanged, setIsDetailsChanged] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (type === 0) {
      setEditedData({
        ...editedData,
        gateway: {
          ...editedData.gateway,
          [name]: value, 
        },
      });
    } else {
      setEditedData({
        ...editedData,
        devices: editedData.devices.map((device, index) =>
          index === 0
            ? {
                ...device,
                [name]: value, 
              }
            : device
        ),
      });
    }
  };

  const saveEdit = async (serialNumber, warrantyStartDate, warrantyEndDate, type) => {
    try{
      if(new Date(warrantyStartDate) > new Date(warrantyEndDate)){
        alert("Warranty start date cannot be later than warranty end date!");
        return; 
      }
      var body = {
        serialNumber: serialNumber,
        warrantyId: deviceData.id ?? "",
        warrantyStartDate: warrantyStartDate,
        warrantyEndDate: warrantyEndDate,
        type: type
      };
      var response = await apiClient.patch(`${apiBaseUrl}/Warranty/updatewarranty`, body);

      if(response){
        const data = await apiClient.get(
          `${apiBaseUrl}/Warranty/deviceinfo?serialNumber=${serialNumber}&type=${type}`
        );
        setDeviceData(data.data);
        setType(type);
        setIsDetailsEdit(!isDetailsEdit);
        alert("Warranty data edited successfully!");
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    if (JSON.stringify(editedData) !== JSON.stringify(deviceData)) {
      setIsDetailsChanged(true); 
    } else {
      setIsDetailsChanged(false); 
    }
  }, [editedData, deviceData]);

  const discardDetailsEdit = () => {
    setIsDetailsEdit(false);
    setEditedData(deviceData);
  }
  
  useEffect(() => {
    setEditedData(deviceData);
  }, [isDetailsEdit]);

  useEffect(() => {
    var warrantyEndDate;
    if (type === 0) {
      warrantyEndDate = new Date(deviceData.gateway.warrantyEndDate);
    } else {
      warrantyEndDate = new Date(deviceData.devices[0].warrantyEndDate);
    }
    if (Date.now() > warrantyEndDate.getTime()) {
      setWarrantyStatus(0);
    } else {
      setWarrantyStatus(1);
    }
  }, [deviceData]);

  useEffect(() => {
    setDeviceData(initialDeviceData);
    setType(initialDeviceType);
    setEditedData(initialDeviceData);
    setWarrantyHistory([]);
  }, [initialDeviceData, initialDeviceType]);

  const navigateToProductWarranty = async (deviceSerial, deviceType) => {
    if (deviceSerial.trim() !== "" && deviceType !== null) {
      setWarrantyHistory([...warrantyHistory, { deviceData, type }]);
      try {
        const response = await apiClient.get(
          `${apiBaseUrl}/Warranty/deviceinfo?serialNumber=${deviceSerial}&type=${deviceType}`
        );
        setDeviceData(response.data);
        setType(deviceType);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const toggleEdit = () => {
    setIsDetailsEdit(!isDetailsEdit);
  };
  const handleBack = async () => {
    if (warrantyHistory.length > 0) {
      const previousWarranty = warrantyHistory[warrantyHistory.length - 1];
      setWarrantyHistory(warrantyHistory.slice(0, -1));
      try {
        const response = await apiClient.get(
          `${apiBaseUrl}/Warranty/deviceinfo?serialNumber=${previousWarranty.deviceData.gateway.serialNumber}&type=${previousWarranty.type}`
        );
        setDeviceData(response.data);
        setEditedData(response.data);
        setType(previousWarranty.type);
      } catch (err) {
        console.error(err);
      }
      // setDeviceData(previousWarranty.deviceData);
      // setEditedData(previousWarranty.deviceData);
      // setType(previousWarranty.type);
    }
  };

  useEffect(() => {
    switch (type) {
      case 0:
        setDeviceType("Gateway");
        break;
      case 1:
        setDeviceType("Switch");
        break;
      case 2:
        setDeviceType("Pocket Remote");
        break;
      case 3:
        setDeviceType("Alarm");
        break;
      case 4:
        setDeviceType("Sensor");
        break;
      case 5:
        setDeviceType("Smart Lock");
        break;
      default:
        setDeviceType("");
        break;
    }
  }, [type]);
  return (
    <div
      className={`h-auto w-auto ${
        warrantyStatus === 0 ? "bg-red-100" : "bg-green-100"
      } p-4`}
    >
      {warrantyHistory.length > 0 && (
        <button
          onClick={handleBack}
          className="mt-4 bg-gray-500 text-white py-2 px-4 rounded flex items-center"
        >
          <FaArrowLeft className="mr-2" />
          Back
        </button>
      )}
      <div className="grid grid-cols-12 gap-4 mt-4 mb-4">
        <div className="col-span-3 bg-white p-4 rounded-lg shadow-md">
          <div className="flex justify-center">
            <img
              src="https://via.placeholder.com/150"
              alt="Product"
              className="w-32"
            />
          </div>
          <h2 className="text-center font-bold mt-4">
            {type === 0
              ? deviceData.gateway.serialNumber
              : deviceData.devices[0].serialNumber}{" "}
            - {deviceType}
          </h2>
          <p
            className={`text-center font-bold mt-2 ${
              warrantyStatus === 0 ? "text-red-600" : "text-green-600"
            }`}
          >
            {warrantyStatus === 0 ? "Expired" : "Active"}
          </p>
        </div>

        {/* Overview Section */}
        <div className="col-span-3 bg-white p-4 rounded-lg shadow-md h-full relative">
          {/* Tab navigation */}
          <div className="flex border-b mb-4">
            <button className="p-2 text-green-600 border-b-2 border-green-600">
              Overview
            </button>
            {!isDetailsEdit ? (
              <button
                onClick={() => toggleEdit()}
                className="absolute top-5 right-5 text-gray-600 w-auto flex flex-row items-center"
              >
                <FaEdit className="mr-2" /> Edit
              </button>
            ) : (
              <div className="absolute top-5 right-5 flex space-x-4">
                {" "}
                {/* Container to hold Save and Cancel buttons */}
                <button
                  disabled={!isDetailsChanged}
                  onClick={() =>
                    saveEdit(
                      type === 0
                        ? editedData.gateway.serialNumber
                        : editedData.devices[0].serialNumber,
                      type === 0
                        ? editedData.gateway.warrantyStartDate
                        : editedData.devices[0].warrantyStartDate,
                      type === 0
                        ? editedData.gateway.warrantyEndDate
                        : editedData.devices[0].warrantyEndDate,
                      type
                    )
                  }
                  className={`${
                    isDetailsChanged
                      ? "text-gray-600"
                      : "text-gray-400 cursor-not-allowed"
                  } w-auto flex flex-row items-center`}
                >
                  <FaSave className="mr-2" /> Save
                </button>
                <button
                  onClick={discardDetailsEdit} // Reset editing state
                  className="text-gray-600 w-auto flex flex-row items-center"
                >
                  <FaTimes className="mr-2" /> Cancel
                </button>
              </div>
            )}
          </div>

          {/* Product Details */}
          <div className="grid grid-cols-1 gap-4">
            {/* Second column */}
            <div className="w-full">
              <p className="text-sm text-gray-600">Model Nane</p>
              <p className="text-lg font-bold w-full">
                {type === 0
                  ? deviceData.gateway.serialNumber.replace(/^(.*?)-.*/, "$1")
                  : deviceData.devices[0].serialNumber.replace(/^(.*?)-.*/, "$1")}
              </p>
            </div>

            {/* Second column */}
            <div className="w-full">
              <p className="text-sm text-gray-600">SN</p>
              <p className="text-lg font-bold w-full">
                {type === 0
                  ? deviceData.gateway.serialNumber
                  : deviceData.devices[0].serialNumber}
              </p>
            </div>

            <div className="w-full">
              <p className="text-sm text-gray-600">Warranty Start</p>
              {isDetailsEdit ? (
                <input
                  type="date"
                  name="warrantyStartDate"
                  value={
                    type === 0
                      ? format(
                          new Date(editedData.gateway.warrantyStartDate),
                          "yyyy-MM-dd"
                        )
                      : format(
                          new Date(editedData.devices[0].warrantyStartDate),
                          "yyyy-MM-dd"
                        )
                  }
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                />
              ) : (
                <p className="text-lg font-bold w-full">
                  {type === 0
                    ? format(
                        new Date(deviceData.gateway.warrantyStartDate),
                        "yyyy-MM-dd"
                      )
                    : format(
                        new Date(deviceData.devices[0].warrantyStartDate),
                        "yyyy-MM-dd"
                      )}
                </p>
              )}
            </div>

            {/* First column */}
            <div className="w-full">
              <p className="text-sm text-gray-600">Warranty End Date</p>
              {isDetailsEdit ? (
                <input
                  type="date"
                  name="warrantyEndDate"
                  value={
                    type === 0
                      ? format(
                          new Date(editedData.gateway.warrantyEndDate),
                          "yyyy-MM-dd"
                        )
                      : format(
                          new Date(editedData.devices[0].warrantyEndDate),
                          "yyyy-MM-dd"
                        )
                  }
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                />
              ) : (
                <p className="text-lg font-bold w-full">
                  {type === 0
                    ? format(
                        new Date(deviceData.gateway.warrantyEndDate),
                        "yyyy-MM-dd"
                      )
                    : format(
                        new Date(deviceData.devices[0].warrantyEndDate),
                        "yyyy-MM-dd"
                      )}
                </p>
              )}
            </div>
          </div>
          {type !== 0 && (
               <div className="flex border-b mb-2 mt-6">
               <h3 className="font-bold mb-2">Gateway Details</h3>
             </div>
          )}
          {type !== 0 && (
                <div className="w-full">
                <p className="text-sm text-gray-600">Gateway SN</p>
                <p className="text-lg font-bold">{deviceData.gateway.serialNumber}</p>
              </div>
          )}
        </div>
        

        {/* Homeowner Section in Its Own White Box */}
        <div className="col-span-6 bg-white p-4 rounded-lg shadow-md">
          <div className="flex border-b mb-4">
            <h3 className="font-bold mb-4">Owner</h3>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full">
              <p className="text-sm text-gray-600">Owner Name</p>
              <p className="text-lg font-bold">{deviceData.owner.name}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">Owner Contact</p>
              <p className="text-lg font-bold">{deviceData.owner.contact}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">Owner Email</p>
              <p className="text-lg font-bold">{deviceData.owner.email}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">Address</p>
              <p className="text-lg font-bold">{deviceData.owner.address}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">Postcode</p>
              <p className="text-lg font-bold">{deviceData.owner.postCode}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">State</p>
              <p className="text-lg font-bold">{deviceData.owner.state}</p>
            </div>
            <div className="w-full">
              <p className="text-sm text-gray-600">Country</p>
              <p className="text-lg font-bold">{deviceData.owner.country}</p>
            </div>
          </div>
          {deviceData.prevOwner != null && (
            <div className="flex border-b mb-2 mt-4">
              <h3 className="font-bold mb-2">Previous Owner</h3>
            </div>
          )}
          {deviceData.prevOwner != null && (
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full">
                <p className="text-sm text-gray-600">Owner Name</p>
                <p className="text-lg font-bold">{deviceData.prevOwner.name}</p>
              </div>
              <div className="w-full">
                <p className="text-sm text-gray-600">Owner Contact</p>
                <p className="text-lg font-bold">
                  {deviceData.prevOwner.contact}
                </p>
              </div>
            </div>
          )}
          {deviceData.prev_PrevOwner != null && (
            <div className="flex border-b mb-2 mt-4">
              <h3 className="font-bold mb-2">Previous Previous Owner</h3>
            </div>
          )}
          {deviceData.prevOwner != null && (
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full">
                <p className="text-sm text-gray-600">Owner Name</p>
                <p className="text-lg font-bold">
                  {deviceData.prev_PrevOwner.name}
                </p>
              </div>
              <div className="w-full">
                <p className="text-sm text-gray-600">Owner Contact</p>
                <p className="text-lg font-bold">
                  {deviceData.prev_PrevOwner.contact}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {type === 0 && (
        <>
          <strong className="block w-full font-lg mt-4 mb-2">
            Gateway Devices:
          </strong>
          <div className="flex flex-wrap gap-4 p-4">
            {deviceData.devices.map((device, index) => {
              const getType = (type) => {
                switch (type) {
                  case 1:
                    return "Switch";
                  case 2:
                    return "Pocket Remote";
                  case 3:
                    return "Alarm";
                  case 4:
                    return "Sensor";
                  case 5:
                    return "Smart Lock";
                  default:
                    return "";
                }
              };
              return (
                <div
                  onClick={() =>
                    navigateToProductWarranty(device.serialNumber, device.type)
                  }
                  key={index}
                  className="p-4 bg-white border border-gray-200 rounded shadow w-60 flex-shrink-0"
                >
                  <ul>
                    <li>
                      <strong>SN:</strong> {device.serialNumber}
                    </li>
                    <li>
                      <strong>Warranty Start:</strong>{" "}
                      {new Date(device.warrantyStartDate).toLocaleDateString()}
                    </li>
                    <li>
                      <strong>Warranty End:</strong>{" "}
                      {new Date(device.warrantyEndDate).toLocaleDateString()}
                    </li>
                    <li>
                      <strong>Installed Date:</strong>{" "}
                      {new Date(device.installedDate).toLocaleDateString()}
                    </li>
                    <li>
                      <strong>Type:</strong> {getType(device.type)}
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default WarrantyContent;
