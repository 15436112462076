import { React, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  ViewGridIcon,
  BellIcon,
  ChartBarIcon,
  HeartIcon,
  CreditCardIcon,
  ChatAltIcon,
  LogoutIcon,
  CogIcon,
} from "@heroicons/react/outline"; // Heroicons for icons
import { NavLink } from "react-router-dom";
import apiClient from "../utils/axiosConfig";
import { GatewayDataContext } from "../dataContext/GatewayDataContext";


function Sidebar() {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { setIsAuthenticated } = useContext(GatewayDataContext);
  async function handleLogout() {
    try {
      await apiClient.post(`${apiBaseUrl}/Auth/logout`);
      navigate("/login");
      localStorage.removeItem("jwtToken");
      deleteCookie("refreshToken");
      localStorage.removeItem("isAuthenticated");
      setIsAuthenticated(false);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  return (
    <div className="w-64 g-gray-100 p-5 flex flex-col">
      {/* Sidebar Header */}
      <div className="flex items-center space-x-2 mb-6">
        <button className="text-gray-500 focus:outline-none" onClick={null}>
          {/* Hamburger Icon */}
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <h1 className="text-lg font-bold">Senzo Admin</h1>
      </div>

      {/* Sidebar Items */}
      <nav className="flex flex-col space-y-4">
        <SidebarItem
          text="Dashboard"
          to="/dashboard"
          icon={<HomeIcon className="h-5 w-5" />}
          active
        />
        <SidebarItem
          text="Gateway Whitelist"
          to="/whitelist"
          icon={<ViewGridIcon className="h-5 w-5" />}
        />
        <SidebarItem
          text="Warranty Management"
          to="/warranty"
          icon={<BellIcon className="h-5 w-5" />}
        />
        <SidebarItem
          text="Gateway Analytics"
          to="/analytics"
          icon={<ChartBarIcon className="h-5 w-5" />}
        />
        <SidebarItem
          text="User Management"
          to="/device"
          icon={<HeartIcon className="h-5 w-5" />}
        />
      </nav>

      <div className="mt-auto space-y-2">
        {/* Logout Button (Non-Navigable) */}
        <button
          onClick={() => handleLogout()} // Replace with actual logout logic
          className="flex items-center p-2 space-x-3 text-black rounded-lg hover:bg-blue-600 hover:text-white transition-colors duration-200"
        >
          <LogoutIcon className="h-5 w-5" />
          <span>Logout</span>
        </button>

        {/* Settings Button (Navigable) */}
        <SidebarItem
          text="Settings"
          to="/settings"
          icon={<CogIcon className="h-5 w-5" />}
          className="bg-blue-500 text-white rounded-lg"
        />
      </div>
    </div>
  );
}

function SidebarItem({ text, to, icon }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center p-2 space-x-3 rounded-lg hover:bg-blue-200 ${
          isActive ? "bg-blue-500 text-white" : "text-gray-700"
        }`
      }
    >
      <span>{icon}</span>
      <span>{text}</span>
    </NavLink>
  );
}

export default Sidebar;
