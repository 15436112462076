import { useState, useRef, useEffect } from "react";
import apiClient from "../utils/axiosConfig";
import { FaEdit, FaTrash, FaArrowLeft, FaSave, FaTimes } from "react-icons/fa";
import { add } from "date-fns";

function UserManagementContent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchCache, setSearchCache] = useState([]);
  const [data, setData] = useState();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [currentGatewayIndex, setCurrentGatewayIndex] = useState(0);
  const searchTimeoutRef = useRef();
  const [editedData, setEditedData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDetailsChanged, setIsDetailsChanged] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value === ""
    ) {
      setSearchData([]);
      setSearchCache([]);
      return;
    }
    try {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchResult(event.target.value);
      }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (JSON.stringify(editedData) !== JSON.stringify(data)) {
      setIsDetailsChanged(true);
    } else {
      setIsDetailsChanged(false);
    }
  }, [editedData, data]);

  useEffect(() => {
    setEditedData(data);
    setIsEdit(false);
  }, [data]);

  const handleSelectionChange = (index) => {
    setCurrentGatewayIndex(index);
  };

  const saveData = async () => {
    var dto = {
      emailAddress: data.email,
      name: editedData.name,
      contact: editedData.contact,
      address: editedData.address,
      postcode: editedData.postcode,
      state: editedData.state,
      country: editedData.country,
    };
    try {
      const result = await apiClient.patch(`${apiBaseUrl}/Owner/updateowner`, dto);

      if (result) {
        const newData = await apiClient.get(
          `${apiBaseUrl}/Owner/ownerDetails?emailAddress=${data.email}`
        );
        setData(newData.data);
        setEditedData(newData.data);
        setIsEdit(false);
        alert("Owner details updated");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const handleBlur = () => {
    setTimeout(() => setSearchData([]), 100);
  };

  const handleFocus = () => {
    if (searchCache.length > 0) {
      setSearchData(searchCache);
    } else {
      setSearchData([]);
    }
  };
  const fetchSearchResult = async (searchTerm) => {
    try {
      const result = await apiClient.get(
        `${apiBaseUrl}/Owner/ownersearch?emailAddress=${searchTerm}`
      );
      if (result) {
        setSearchData(result.data.searchResult);
        setSearchCache(result.data.searchResult);
      }
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleEdit = () => {
    setIsEdit(true);
  };

  const handleResultClick = async (emailAddress) => {
    try {
      const result = await apiClient.get(
        `${apiBaseUrl}/Owner/ownerDetails?emailAddress=${emailAddress}`
      );

      if (result) {
        setEditedData(result.data);
        setData(result.data);
      }
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const discardDetailsEdit = () => {
    setEditedData(data);
    setIsEdit(false);
  };

  return (
    <div className="h-auto w-auto bg-blue-100 p-4 pb-8">
      <div className="mb-4 mr-4 ml-4 mt-4 relative">
        <input
          type="text"
          placeholder="Search for user's email address..."
          value={searchTerm}
          onChange={handleSearch}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className="border p-2 w-full border-blue-500 rounded-lg relative"
        />
        {searchData.length > 0 && (
          <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-48 overflow-y-auto">
            {searchData.map((result, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleResultClick(result.emailAddress)}
              >
                {result.emailAddress}
              </div>
            ))}
          </div>
        )}
      </div>
      {data && (
        <div>
          <div className="bg-white ml-4 mr-4 p-4 rounded-lg w-auto">
            <div className="relative">
              <h1 className="font-bold mb-4">Owner Details</h1>
              {!isEdit ? (
                <button
                  onClick={() => toggleEdit()}
                  className="absolute top-0 right-0 text-gray-600 w-auto flex flex-row items-center"
                >
                  <FaEdit className="mr-2" /> Edit
                </button>
              ) : (
                <div className="absolute top-0 right-0 flex space-x-4">
                  {" "}
                  {/* Container to hold Save and Cancel buttons */}
                  <button
                    disabled={!isDetailsChanged}
                    onClick={() => saveData()}
                    className={`${
                      isDetailsChanged
                        ? "text-gray-600"
                        : "text-gray-400 cursor-not-allowed"
                    } w-auto flex flex-row items-center`}
                  >
                    <FaSave className="mr-2" /> Save
                  </button>
                  <button
                    onClick={discardDetailsEdit} // Reset editing state
                    className="text-gray-600 w-auto flex flex-row items-center"
                  >
                    <FaTimes className="mr-2" /> Cancel
                  </button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-5">
              <div className="col-span-1">
                <div className="flex justify-center flex-row">
                  <img
                    src="https://via.placeholder.com/500"
                    alt="Product"
                    className="w-40"
                  />
                </div>
              </div>
              <div className="col-span-3 grid grid-cols-3">
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Owner Name</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="name"
                        value={editedData.name}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.name}</p>
                  )}
                </div>

                <div className="w-full">
                  <p className="text-sm text-gray-600">Address</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="address"
                        value={editedData.address}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.address}</p>
                  )}
                </div>
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Gateway Count</p>
                  <p className="text-lg font-bold">{data.gatewayCount}</p>
                </div>
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Owner Contact</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="contact"
                        value={editedData.contact}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.contact}</p>
                  )}
                </div>
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Postcode</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="postcode"
                        value={editedData.postcode}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.postcode}</p>
                  )}
                </div>
                <div></div>
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Owner Email</p>
                  <p className="text-lg font-bold">{data.email}</p>
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-600">State</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="state"
                        value={editedData.state}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.state}</p>
                  )}
                </div>
                <div></div>
                <div></div>
                <div className="w-full mb-4">
                  <p className="text-sm text-gray-600">Country</p>
                  {isEdit ? (
                    <div>
                      <input
                        type="text"
                        name="country"
                        value={editedData.country}
                        onChange={handleInputChange}
                        className="border p-2 w-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-lg font-bold">{data.country}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {data.gateway.length > 0 && (
            <>
              <div className="bg-white ml-4 mr-4 p-4 rounded-lg w-auto mt-4">
                <SelectionButton
                  data={data.gateway}
                  onSelectionChange={handleSelectionChange}
                />
                <h1 className="font-bold mb-4">Gateway Associated</h1>
                <div className="bg-gray-600 p-4 rounded-lg w-auto">
                  <div className="grid grid-cols-3">
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">Gateway SN</p>
                      <p className="text-lg font-bold text-white">
                        {data.gateway[currentGatewayIndex].gatewaySN}
                      </p>
                    </div>
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">Warranty Start</p>
                      <p className="text-lg font-bold text-white">
                        {new Date(
                          data.gateway[currentGatewayIndex].warrantyStartDate
                        ).toLocaleDateString("en-US")}
                      </p>
                    </div>
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">RFID</p>
                      <p className="text-lg font-bold text-white">
                        {data.gateway[currentGatewayIndex].rfid}
                      </p>
                    </div>
                    <div></div>
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">Warranty End</p>
                      <p className="text-lg font-bold text-white">
                        {new Date(
                          data.gateway[currentGatewayIndex].warrantyEndDate
                        ).toLocaleDateString("en-US")}
                      </p>
                    </div>
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">RFID Channel</p>
                      <p className="text-lg font-bold text-white">
                        {data.gateway[currentGatewayIndex].rfidChannel}
                      </p>
                    </div>
                    <div></div>
                    <div className="w-full mb-4">
                      <p className="text-sm text-white">Installed Date</p>
                      <p className="text-lg font-bold text-white">
                        {new Date(
                          data.gateway[currentGatewayIndex].installedDate
                        ).toLocaleDateString("en-US")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {data.gateway[currentGatewayIndex].deviceList.length > 0 && (
                <div className="bg-white ml-4 mr-4 p-4 rounded-lg w-auto mt-4">
                  <h1 className="font-bold mb-4">Devices</h1>
                  <table
                    className={`table-auto w-full border-collapse border border-gray-200 transition-opacity duration-300`}
                  >
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border p-2">Index</th>
                        <th className="border p-2">Device SN</th>
                        <th className="border p-2">Warranty Start Date</th>
                        <th className="border p-2">Warranty End Date</th>
                        <th className="border p-2">Installed Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.gateway[currentGatewayIndex].deviceList.map(
                        (device, index) => {
                          return (
                            <tr key={device.deviceSN}>
                              <td className="border p-2">{index + 1}</td>
                              <td className="border p-2">{device.deviceSN}</td>
                              <td className="border p-2">
                                {new Date(
                                  device.warrantyStartDate
                                ).toLocaleDateString("en-US")}
                              </td>
                              <td className="border p-2">
                                {new Date(
                                  device.warrantyEndDate
                                ).toLocaleDateString("en-US")}
                              </td>
                              <td className="border p-2">
                                {new Date(
                                  device.installedDate
                                ).toLocaleDateString("en-US")}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

const SelectionButton = ({ data, onSelectionChange }) => {
  // Array of selections
  const selections = data.map((gateway, index) => gateway.gatewaySN);
  // State to track the current selection index and dropdown visibility
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Handler to move to the previous selection
  const handlePrevious = () => {
    const newIndex =
      currentIndex < selections.length - 1 ? currentIndex + 1 : 0;
    updateSelection(newIndex);
  };

  const updateSelection = (newIndex) => {
    setCurrentIndex(newIndex);
    onSelectionChange(newIndex);
  };

  // Handler to move to the next selection
  const handleNext = () => {
    const newIndex =
      currentIndex > 0 ? currentIndex - 1 : selections.length - 1;
    updateSelection(newIndex);
  };

  // Handler to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Handler for selecting an item from the dropdown
  const handleSelectionClick = (index) => {
    updateSelection(index);
    setDropdownVisible(false);
  };

  return (
    <div className="flex items-center space-x-2 mb-4">
      {/* Previous Button */}
      <button
        className="pl-3 pr-3 h-8 bg-gray-300 rounded hover:bg-gray-400 items-center"
        onClick={handlePrevious}
        disabled={data.length <= 1}
      >
        &lt;
      </button>

      {/* Current Selection Button with Dropdown */}
      <div className="relative">
        <button
          className="pl-2 pr-2 bg-gray-500 text-white rounded text-sm h-8"
          onClick={toggleDropdown}
        >
          <span>{selections[currentIndex]}</span>
        </button>

        {/* Dropdown Menu */}
        {dropdownVisible && (
          <div className="absolute mt-2 bg-white border border-gray-300 rounded shadow-lg">
            <ul>
              {selections.map((selection, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelectionClick(index)}
                >
                  <p className="text-sm">{selection}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Next Button */}
      <button
        className="pl-3 pr-3 bg-gray-300 rounded hover:bg-gray-400 h-8"
        onClick={handleNext}
        disabled={data.length <= 1}
      >
        &gt;
      </button>
    </div>
  );
};

export default UserManagementContent;
