import React, { useState, useContext } from "react";
import senzoLogo from '../assets/SENZO_SINCE 1995_32KB.png';
import { useNavigate } from 'react-router-dom';
import apiClient from '../utils/axiosConfig';
import { GatewayDataContext } from "../dataContext/GatewayDataContext";

function Login() {
  const { setIsAuthenticated } = useContext(GatewayDataContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("API Base URL:", apiBaseUrl); // For debugging

    function handleInputChange(event) {
        const { name, value } = event.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    }

    async function handleLoginSubmit(event) {
      event.preventDefault();
      try {
        const response = await apiClient.post(
          `${apiBaseUrl}/auth/login`,
          {
            email: username,
            password,
          },
          {
            withCredentials: true,
          }
        );
        const token = response.data.token;
        if (token) {
          localStorage.setItem("jwtToken", token); 
          navigate("/dashboard"); 
          setIsAuthenticated(true);
        } else {
          console.error("No token returned");
        }
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
    return (
      <div className="min-h-screen flex items-center justify-center bg-blue-600">
        <div className="bg-white w-full max-w-md p-8 space-y-6 rounded-lg shadow-lg">
          <div className="flex justify-center">
          <img src={senzoLogo} alt="senzoLogo" className="h-auto w-auto max-h-32" />
          </div>
          <form className="space-y-6" onSubmit={handleLoginSubmit}>
          {/* Username Field */}
          <div>
            <label htmlFor="username" className="sr-only">
              Username
            </label>
            <div className="relative">
              <input
                onChange={handleInputChange}
                id="username"
                name="username"
                type="text"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Username"
              />
              
            </div>
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="relative">
              <input
              onChange={handleInputChange}
                id="password"
                name="password"
                type="password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Password"
              />
            </div>
          </div>

          {/* Login Button */}
          <div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
            <span className="">LOGIN</span>
            </button>
          </div>
        </form>
        </div>
      </div>
    );
}

export default Login;