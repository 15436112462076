import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./login_page/login";
import Dashboard from "./dashboard_page/dashboard";
import WhiteList from "./gateway_whitelist/whitelist_page";
import Warranty from "./warranty/warranty";
import DeviceInfoPage from "./device_info/device_info";
import AnalyticsPage from "./analytics/analytics_page";
import GatewayConnectivityLogsPage from "./analytics/gateway_connectivity_logs";
import ProtectedRoute from "./components/ProtectedRoute";
import GatewayStatusPage from "./analytics/gateway_status_page";
import { GatewayDataProvider } from "./dataContext/GatewayDataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GatewayDataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whitelist"
            element={
              <ProtectedRoute>
                <WhiteList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/warranty"
            element={
              <ProtectedRoute>
                <Warranty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device"
            element={
              <ProtectedRoute>
                <DeviceInfoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <AnalyticsPage />
              </ProtectedRoute>
            }
          >
            <Route path="connectivity" element={<GatewayConnectivityLogsPage/>} />
          </Route>
          <Route
          path="/gatewayConnectStatus"
          element={
            <ProtectedRoute>
              <GatewayStatusPage/>
            </ProtectedRoute>
          }/>
        </Routes>
      </Router>
    </GatewayDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
